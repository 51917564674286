<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclability/cyclabilityData'
import type { LngLatLike } from 'maplibre-gl'

defineOptions({
  name: 'CyclabilityMapSegments',
})

withDefaults(defineProps<CyclabilityMapSegmentsProps>(), {
  zoom: 7,
  center: () => [-0.09, 51.505],
  data: () => ({}),
})

const emit = defineEmits(['onZoom'])

interface CyclabilityMapSegmentsProps {
  zoom?: number
  center?: LngLatLike
  data: CyclabilityData
  legendTitle?: string
  isLoading?: boolean
}

const { selectedArea } = useSelectedAreaFromUrl()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
    @zoomend="({ zoom }) => emit('onZoom', zoom)"
  >
    <MapLibreLayerKeepOrder>
      <CitySegments
        v-if="data?.roads"
        layer-id="city-segments"
        v-bind="data.roads"
        :selected="selectedArea"
      />

      <CityNetwork
        v-if="data?.network"
        layer-id="city-network"
        v-bind="data.network"
        :is-loading="isLoading"
      />

      <CellsGrid
        v-if="data?.areas"
        layer-id="cells-grid"
        v-bind="data.areas"
      />
    </MapLibreLayerKeepOrder>

    <MapSelectArea
      :layers-ids="['city-segments']"
      :show-button="false"
    />

    <CyclabilityMapLegends :data="data" />
  </MapLibre>

  <CyclabilitySidebarSegment :data="data" />
</template>
