<script setup lang="ts">
import type { LngLatBoundsLike, LngLatLike } from 'maplibre-gl'
import bbox from '@turf/bbox'
import center from '@turf/center'

const props = defineProps<{
  area: GeoJSON.FeatureCollection<GeoJSON.Polygon | GeoJSON.MultiPolygon>
}>()

const { area } = toRefs(props)
const geojson = ref<GeoJSON.FeatureCollection<GeoJSON.Polygon | GeoJSON.MultiPolygon>>()
const bounds = ref<LngLatBoundsLike>()
const areaCenter = ref<LngLatLike>()

const { classBreaks } = useClassBreaks(useExtractMean(area, 'km_network_per_km2'), 5, 'Blues')
const paint = useExpressionColorFromBreaks(classBreaks, 'km_network_per_km2')

whenever(area, (area) => {
  const { features } = area

  if (!features || features.length === 0) {
    return
  }

  geojson.value = area

  // enforce the type of bbox to LngLatBoundsLike because -> https://github.com/Turfjs/turf/issues/1297
  bounds.value = bbox(geojson.value) as LngLatBoundsLike
  // enforce the type of coordinates to LngLatLike because -> https://github.com/Turfjs/turf/issues/1297
  areaCenter.value = center(geojson.value).geometry.coordinates as LngLatLike
}, { immediate: true })
</script>

<template>
  <MapLibre
    :center="areaCenter"
    :bounds="bounds"
    :max-zoom="14"
    :min-zoom="9"
    :scale-control="false"
  >
    <MapLibreSourceGeojson
      v-if="geojson"
      id="area"
      :layer-props="{
        type: 'fill',
        paint: {
          'fill-color': paint,
          'fill-opacity': 0.7,
        },
      }"
      :data="geojson"
    />
  </MapLibre>
</template>
