import { GeometriesTypes, type GeometryType } from '@/types/index.types'
import { useMagicKeys } from '@vueuse/core'

const NAME_SEPARATOR = '::'
const SPACE_SEPARATOR = '%%'
const SEPARATOR = '__'

type MaybeGeometryType = GeometryType | null | undefined

function isHexGeometry(type?: MaybeGeometryType) {
  return type?.indexOf('h3_') === 0
}

// Areas are encoded as `type'id` where `type` is the geometry type and `id` is the area id
export function getGeometryTypeFromUrl(areas?: string): GeometryType {
  const id = areas?.split(SEPARATOR)[0]

  if (!id) {
    return GeometriesTypes.H3_8
  }

  const [type] = id.split(NAME_SEPARATOR) as [GeometryType]
  return type || GeometriesTypes.H3_8
}

export function useSelectedAreaFromUrl(geometryType?: MaybeRef<MaybeGeometryType>) {
  const route = useRoute()
  const router = useRouter()
  const { control, command } = useMagicKeys()

  const geometryTypeRef: Ref<MaybeGeometryType> = ref(geometryType)

  const selectedArea = computed({
    set(v: string[]) {
      router.push({
        params: {
          areas: v.length ? v.map(n => encodeAreaName(n)).join(SEPARATOR) : '',
        },
      })
    },
    get() {
      const areas = Array.isArray(route.params.areas) ? route.params.areas[0] : route.params.areas
      if (!areas || areas === '') {
        return []
      }

      return areas.split(SEPARATOR).map((name: string) => decodeAreaId(name))
    },
  })

  function getAreaName(id: string | number, separator = NAME_SEPARATOR) {
    const isHex = isHexGeometry(unref(geometryTypeRef))
    return `${isHex ? `HEX${separator}` : ''}${id}`
  }

  function encodeAreaName(id: string | number, type?: MaybeGeometryType) {
    const typeRef = type || unref(geometryTypeRef)

    if (!typeRef) {
      return encodeURIComponent(`${id}`.replace(' ', SPACE_SEPARATOR))
    }

    return encodeURIComponent(
      `${typeRef}${NAME_SEPARATOR}${id}`.replace(' ', SPACE_SEPARATOR),
    )
  }

  function decodeAreaId(name: string) {
    const decodedName = decodeURIComponent(name)
    const id = decodedName.includes(NAME_SEPARATOR) ? decodedName.split(NAME_SEPARATOR)[1] : decodedName
    return id.replace(SPACE_SEPARATOR, ' ')
  }

  function onSelectArea(name?: string) {
    if (!name) {
      return
    }

    if (get(command) || get(control)) {
      if (selectedArea.value.includes(name)) {
        set(
          selectedArea,
          get(selectedArea).filter(n => n !== name),
        )
      } else {
        set(selectedArea, [...get(selectedArea), name])
      }
    } else {
      if (
        selectedArea.value.length === 1
        && selectedArea.value.includes(name)
      ) {
        set(selectedArea, [])
      } else {
        set(selectedArea, [name])
      }
    }
  }

  function unselectAllAreas() {
    set(selectedArea, [])
  }

  watch(geometryTypeRef, unselectAllAreas)

  return {
    selectedArea,
    onSelectArea,
    unselectAllAreas,
    getAreaName,
  }
}
