import type { DateRange } from '@/stores/filters'
import {
  differenceInDays,
  endOfDay,
  isWithinInterval,
  startOfDay,
  subDays,
  subMonths,
} from 'date-fns'
import Papa from 'papaparse'

interface CyclabilityData {
  date: Date
  freeFloatingVehicles: number
  stationBasedVehicles: number
  freeFloatingTrips: number
  stationBasedTrips: number
  ecoCounter: number
}

interface ParsedCyclabilityData {
  day: string
  nb_vehic_avail_ff: number
  nb_vehic_avail_sb: number
  nb_trip_ff: number
  nb_trip_sb: number
  eco_compteur: number
  month: number
  year: number
}

export const useBarcelonaData = createSharedComposable(() => {
  const filtersStore = useFiltersStore()
  const { dateRange, dateRangeLimit } = storeToRefs(filtersStore)

  const lastDateRange = computed(() => getLastDateRange(dateRange.value))
  const dataInRange = computed(() => getDataInRange(dateRange.value))
  const comparedDataInRange = computed(() => getDataInRange(lastDateRange.value))

  // Fetch and parse the CSV data
  const { data, isFetching, execute } = useFetch<CyclabilityData[]>('/data/cyclabiity_data_barcelona.csv', {
    afterFetch: (context) => {
      const parsed = Papa.parse<ParsedCyclabilityData>(context.data, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      })

      context.data = parsed.data.map((row) => {
        return {
          date: new Date(row.day),
          freeFloatingVehicles: row.nb_vehic_avail_ff,
          stationBasedVehicles: row.nb_vehic_avail_sb,
          freeFloatingTrips: row.nb_trip_ff,
          stationBasedTrips: row.nb_trip_sb,
          ecoCounter: row.eco_compteur,
        }
      })

      return context
    },
    immediate: false,
  })

  const loadData = async () => {
    await execute()
  }

  watch(data, (data) => {
    if (data) {
      const end = data[data.length - 1].date

      dateRange.value = {
        start: data.length > 60 ? startOfDay(subMonths(end, 2)) : data[0].date,
        end,
      }

      dateRangeLimit.value = {
        start: data[0].date,
        end,
      }
    }
  })

  function getLastDateRange(dateRange: DateRange) {
    const { start, end } = dateRange
    const nbrDays = differenceInDays(end, start)

    return {
      start: startOfDay(subDays(start, nbrDays + 1)),
      end: endOfDay(subDays(start, 1)),
    }
  }

  function getDataInRange(dateRange: DateRange) {
    return data.value?.filter((item) => {
      return isWithinInterval(item.date, { start: dateRange.start, end: dateRange.end })
    })
  }

  function resetDateRange() {
    filtersStore.$reset()
  }

  return {
    dateRange,
    dateRangeLimit,
    lastDateRange,
    data,
    dataInRange,
    comparedDataInRange,
    loading: isFetching,
    getDataInRange,
    getLastDateRange,
    loadData,
    resetDateRange,
  }
})
