import type { ModeOrAll } from '@/types/modes.types'
import type { ApolloError, OperationVariables, TypedDocumentNode } from '@apollo/client'

const queries = Object.entries(
  import.meta.glob('@/graphql/queries/*.gql', {
    eager: true,
    import: 'default',
  }),
)

interface FiltersVariables {
  dateRange: { start: string, end: string }
  city?: string
  provider?: string
  comparativeDateRange?: { start: string, end: string }
  timeMode?: string
  regions?: string[]
  country?: string
  countries?: string[]
  vehicleType?: string
  carPropulsion?: string[]
  carSize?: string[]
  carModel?: string[]
}

interface Variables extends FiltersVariables, OperationVariables {}

interface MetricsDataOptions<T> {
  kpis?: string[] | null | Ref<string[] | null>
  middleware?: ((d: any) => T) | null
  queryOptions?: OperationVariables
}

interface QueryResponse {
  [key: string]: {
    data: any
    metric: string | null
    help: { title: string, content: string } | null
  }
}

export function useMetricData<T = any>(query: MaybeRef<string | null>, variables: MaybeRef<Partial<Variables>>, options: MetricsDataOptions<T> = {
  kpis: null,
  middleware: null,
  queryOptions: {},
}) {
  const { kpis, middleware, queryOptions } = options
  const route = useRoute()

  const filtersStore = useFiltersStore()

  const data = ref<T | null>(null)
  const metric = ref<string | null>(null)
  const help = ref<{ title: string, content: string } | null>(null)
  const loadedOnce = ref(false)

  const queryRef = ref(query)
  const variablesRef = ref(variables)

  const generatedVariables = computed((): Variables => {
    const mode = route.params.mode as ModeOrAll
    const vehicleType
      = mode === VEHICLE_TYPE_ALL ? undefined : ENUM_VEHICLE_TYPES[mode]

    const defaultVariables = {
      city: route.params.citySlug as string,
      provider: route.params.provider as string,
      dateRange: filtersStore.dateRangeStr,
      comparativeDateRange: filtersStore.comparativeRangeStr || undefined,
      timeMode: filtersStore.timeMode || undefined,
      regions: filtersStore.regions || undefined,
      country: filtersStore.country || undefined,
      countries: filtersStore.country ? [filtersStore.country] : undefined,
      vehicleType,
    }

    const variablesValue = unref(variablesRef)

    if (mode === VEHICLE_TYPE_CAR) {
      const carSpecsVariables = {
        carPropulsion: filtersStore.carPropulsion.length
          ? filtersStore.carPropulsion
          : undefined,
        carSize: filtersStore.carSize.length ? filtersStore.carSize : undefined,
        carModel: filtersStore.carModel.length
          ? filtersStore.carModel
          : undefined,
      }

      return {
        ...defaultVariables,
        ...carSpecsVariables,
        ...variablesValue,
      }
    }

    return {
      ...defaultVariables,
      ...variablesValue,
    }
  })

  const gql = computed((): TypedDocumentNode | undefined => {
    const queryValue = unref(queryRef)
    const file
      = queryValue
      && queries.find(([path]) => path.includes(`/${queryValue}.gql`))

    return file?.[1] as TypedDocumentNode
  })

  const { onResult, loading, error } = (gql.value
    && useQuery(
      gql,
      generatedVariables,
      computed(() => {
        return {
          ...queryOptions,
          returnPartialData: false,
        }
      }),
    )) || {
    onResult: () => {},
    loading: ref(false),
    error: get(query) && ref(new Error(`Query: "${get(query)}" not found`)) as Ref<ApolloError | null>,
  }

  onResult((res) => {
    if (middleware && typeof middleware !== 'function') {
      throw new Error('middleware must be a function')
    }

    if (res?.partial) {
      return
    }

    set(loadedOnce, true)

    const resData = res?.data as QueryResponse || null
    const queryValue = unref(queryRef) as keyof QueryResponse
    const kpisValue = unref(kpis)

    let d = null
    if (resData) {
      if (kpisValue && Array.isArray(kpisValue)) {
        d = kpisValue?.map(kpi =>
          resData[kpi].data !== undefined ? resData[kpi].data : resData[kpi],
        )
      } else if (queryValue) {
        d = resData[queryValue]?.data !== undefined
          ? resData[queryValue].data
          : resData[queryValue]
      }

      // data
      set(data, middleware ? middleware(d) : d)
    }

    // metric
    set(metric, resData[queryValue]?.metric)

    // help
    set(help, resData[queryValue]?.help)
  })

  return {
    variables: generatedVariables,
    data,
    metric,
    help,
    loading,
    loadedOnce,
    error,
  }
}
