<script setup lang="ts">
defineOptions({
  name: 'CityCyclabilityPassagesHistoryMetric',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())
const { writeCache } = useWriteCacheForMetric('cityCyclabilityPassagesHistory')
const { dateRange, dataInRange } = useBarcelonaData()

function updateCachedData() {
  if (!city.value) {
    return
  }

  writeCache({
    city: city.value?.name,
    dateRange: dateRange.value,
  }, {
    series: [
      {
        name: 'all',
        label: 'All',
        color: '#33488a',
        data: dataInRange.value?.map(item => [formatDate(item.date), item.ecoCounter]) || [],
      },
    ],
  })
}

watch([dataInRange], () => {
  updateCachedData()
}, { immediate: true })
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityPassagesHistory"
    :used-filters="['dateRange']"
    :variables="{
      dateRange,
    }"
  >
    <template #title>
      {{ t('cyclability.cycling_passages_history.title') }}
    </template>

    <template #subtitle>
      {{ t('from Eco-Compteur') }}
    </template>

    <template #default="{ data }">
      <BarMetric :data="data" />
    </template>
  </MetricCard>
</template>
