import type { GeoJSONSource, Map, SourceSpecification } from 'maplibre-gl'

export function useSource<T = GeoJSONSource>(id: MaybeRef<string>) {
  const idRef = ref(id)
  const map = inject<Ref<Map | null>>('map', ref(null))

  function removeSource() {
    if (getSource()) {
      map.value?.removeSource(idRef.value)
    }
  }

  function updateSource(source: SourceSpecification) {
    removeSource()
    map.value?.addSource(idRef.value, source)
  }

  function getSource() {
    return map.value?.getSource(idRef.value) as T
  }

  return {
    getSource,
    updateSource,
    removeSource,
  }
}
