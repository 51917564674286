<script setup>
defineProps({
  noComparison: Boolean,
})
const { t } = useI18n()
const filtersStore = useFiltersStore()

const {
  city,
  periods: cityPeriods,
  periodsEmpty: cityPeriodsEmpty,
} = storeToRefs(useCityStore())
const {
  provider,
  periods: providerPeriods,
  periodsEmpty: providerPeriodsEmpty,
} = storeToRefs(useProviderStore())

const { dateRange, comparativeRange, toggleComparativeRange }
  = useDateRangeFilter()

const disabledDates = computed(() => {
  if (city.value) {
    return cityPeriodsEmpty.value
  } else if (provider.value) {
    return providerPeriodsEmpty.value
  } else {
    return []
  }
})

const minDate = computed(() => {
  if (city.value) {
    return cityPeriods.value[0]?.start
  } else if (provider.value) {
    return providerPeriods.value[0]?.start
  } else if (filtersStore.dateRangeLimit) {
    return filtersStore.dateRangeLimit.start || null
  }

  return null
})
</script>

<template>
  <DPicker
    id="dateRange"
    :label="t('Time period')"
    icon="calendar"
    :auto-close="false"
  >
    <template #text>
      <p class="whitespace-nowrap overflow-hidden text-ellipsis">
        {{ filtersStore.dateRangeFormated }}
      </p>
      <p
        v-if="filtersStore.comparativeRange"
        class="text-xs font-normal leading-3 whitespace-nowrap overflow-hidden text-ellipsis"
      >
        {{ t('date_vs_date') }} {{ filtersStore.comparativeFormated }}
      </p>
    </template>

    <DateRangePicker
      v-model:date-range="dateRange"
      v-model:comparative-range="comparativeRange"
      :with-comparison="!noComparison"
      :disabled-dates="disabledDates"
      :min-date="minDate"
      :max-date="filtersStore.dateRangeLimit?.end"
      @compare="() => toggleComparativeRange(dateRange)"
    />
  </DPicker>
</template>
