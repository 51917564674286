<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclability/cyclabilityData'

defineOptions({
  name: 'CyclabilityMapLegends',
})

const props = defineProps<CyclabilityMapSegmentsProps>()

interface CyclabilityMapSegmentsProps {
  data: CyclabilityData
  isLoading?: boolean
}

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
const expanded = ref<string>()

function toggleExpanded(layer: string) {
  expanded.value === layer ? expanded.value = undefined : expanded.value = layer
}

onMounted(() => {
  expanded.value = props.data.roads ? 'roads' : props.data.areas ? 'areas' : 'network'
})
</script>

<template>
  <DefineTemplate v-if="data">
    <DLoader v-if="isLoading" />

    <div class="flex flex-col gap-4 w-64 relative">
      <CyclabilityMapLegendCollapsable
        v-if="data.areas"
        layer="areas"
        :expanded="expanded === 'areas'"
        :layer-data="data.areas"
        @toggle-expanded="(v) => toggleExpanded(v)"
      />

      <CyclabilityMapLegendCollapsable
        v-if="data.network"
        layer="network"
        :expanded="expanded === 'network'"
        :layer-data="{
          ...data.network,
          classBreaks: data.network.classBreaks,
        }"
        @toggle-expanded="(v) => toggleExpanded(v)"
      />

      <CyclabilityMapLegendCollapsable
        v-if="data.roads"
        layer="roads"
        :layer-data="data.roads"
        :expanded="expanded === 'roads'"
        @toggle-expanded="(v) => toggleExpanded(v)"
      />
    </div>
  </DefineTemplate>

  <SidebarMobileControls>
    <template #default="{ isMobile }">
      <MapLibreControl
        v-if="!isMobile"
        position="bottom-left"
      >
        <ReuseTemplate />
      </MapLibreControl>
    </template>

    <template #mobile>
      <ReuseTemplate />
    </template>
  </SidebarMobileControls>
</template>
