<script setup lang="ts">
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { isFreemium, screenCities } = storeToRefs(useUserStore())
const cityStore = useCityStore()
const { city, isLoading } = storeToRefs(cityStore)
const { mode } = useMode()

// set filters from user preferences
useFiltersFromPreferences('cities')

const hasScreenAccess = computed(() => screenCities.value.includes(route.params.citySlug as string))

const menu = computed(() => {
  const params = {
    mode: mode.value || VEHICLE_TYPE_ALL,
  }

  return [
    {
      label: t('Overview'),
      route: { name: 'CityOverview', params },
      icon: 'overview',
    },
    ...(hasScreenAccess.value
      ? [
          {
            label: t('Velovision'),
            icon: 'cyclability-report',
            subMenuLabel: t('Focus on'),
            subMenuIcon: 'target',
            subMenu: [
              {
                label: t('City cycling overview'),
                route: { name: 'CyclabilityOverview' },
              },
              {
                label: t('City bikeability'),
                route: {
                  name: 'CyclabilityMapBikeability',
                  params: {
                    ...route.params,
                    areas: '',
                  },
                },
              },
              {
                label: t('Path bikeability'),
                route: {
                  name: 'CyclabilityMapPath',
                  params: {
                    ...route.params,
                    areas: '',
                  },
                },
              },
              {
                label: t('Shared bikes patterns'),
                route: { name: 'CyclabilityMapPatterns' },
              },
            ],
          },
        ]
      : []),
    {
      label: t('Fleets & Trips'),
      icon: 'fleet-and-trips',
      subMenu: [
        {
          route: { name: 'CityFleetsTrips', params, hash: '#at-a-glance' },
          label: t('At a glance'),
          isActive: route.hash === '#at-a-glance',
        },
        {
          route: { name: 'CityFleetsTrips', params, hash: '#fleets' },
          label: t('Fleets'),
          isActive: route.hash === '#fleets',
        },
        {
          route: { name: 'CityFleetsTrips', params, hash: '#trips' },
          label: t('Trips'),
          isActive: route.hash === '#trips',
        },
      ],
    },
    {
      label: t('Maps'),
      icon: 'maps',
      subMenuLabel: t('Selected map'),
      subMenuIcon: 'paper-map',
      subMenu: [
        {
          label: t('Fleet distribution'),
          route: {
            name: 'CityMapsDistribution',
            params,
          },
          isActive: route.name?.toString().includes('CityMapsDistribution'),
        },
        {
          label: t('Travel patterns'),
          route: {
            name: 'CityMapsPatterns',
            params,
          },
          isActive: route.name?.toString().includes('CityMapsPatterns'),
        },
      ],
    },
    {
      label: t('Newsfeed'),
      icon: 'newsfeed',
      route: (!get(isFreemium) && { name: 'CityNewsfeed', params }) || null,
      locked: get(isFreemium) && t('upgrade_to_unlock'),
    },
  ]
})

watch(city, (city) => {
  if (city) {
    const vehicleTypes
      = city.vehicleTypes
        ?.map(v => v.type)
        .filter(type => DATA_VEHICLE_TYPES.includes(type)) || []

    const vehicleTypesFiltered = vehicleTypes?.filter(type =>
      DATA_VEHICLE_TYPES.includes(type),
    )

    if (vehicleTypesFiltered) {
      const vehicleType = mode.value ? ENUM_VEHICLE_TYPES[mode.value] : null

      if (
        vehicleType
        && vehicleTypesFiltered?.length > 1
        && !vehicleTypesFiltered.includes(vehicleType)
      ) {
        router.replace({
          params: {
            ...route.params,
            citySlug: city.name,
            mode: VEHICLE_TYPE_ALL,
          },
        })
      } else if (
        vehicleTypesFiltered?.length === 1
        && (!vehicleType || vehicleType !== vehicleTypesFiltered[0])
      ) {
        router.replace({
          params: {
            ...route.params,
            citySlug: city.name,
            mode: getVehicleType(vehicleTypesFiltered[0]),
          },
        })
      }
    }
  }
})

onMounted(async () => {
  if (route.params.citySlug) {
    await cityStore.load(route.params.citySlug as string)
  }
})

onBeforeUnmount(() => {
  cityStore.unload()
})

watch(
  () => route.params.citySlug,
  async (nextCity, lastCity) => {
    if (nextCity && nextCity !== lastCity) {
      await cityStore.load(nextCity as string)
    }
  },
)
</script>

<template>
  <div>
    <SidebarContainer
      v-if="!isLoading && city"
      class="flex"
      :no-padding="Boolean(route.meta.isMaps)"
      have-menu
    >
      <template #left>
        <Sidebar
          :to="{ name: 'Cities' }"
          :text="t('Back to cities')"
          :menu="menu"
        >
          <FavoriteCard
            type="city"
            :slug="route.params.citySlug as string"
            class="mb-4 hidden md:block"
          />
        </Sidebar>
      </template>

      <template #default="{ container }">
        <router-view :infinite-target="container" />
      </template>
    </SidebarContainer>

    <div
      v-else
      class="flex h-full items-center justify-center"
    >
      <AnimatedLoading />
    </div>
  </div>
</template>
