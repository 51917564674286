import type { GeometryType } from '@/types/index.types'
import type { LngLatLike } from 'maplibre-gl'
import type { Reactive } from 'vue'
import type { ClassBreaks } from '../classBreaks'

type AvailableFilters<T = string> = {
  name: T
  label: string
}[]

enum LayerTypes {
  PATHS = 'roads',
  AREAS = 'areas',
  NETWORK = 'network',
}

type LayerType = `${LayerTypes}`

interface MetricDefinition {
  metric: string
  classBreaks: ClassBreaks
}

interface DataDefinition {
  name: string
  type?: 'geometryType' | 'networkType'
  metrics: MetricDefinition[]
}

interface LayerDefinition<T extends LayerType = LayerType> {
  layer: T
  data: DataDefinition[]
}

type ConfigFile<T extends LayerType = LayerType> = LayerDefinition<T>[]

const FILE_PATH_BASE = import.meta.env.VITE_SCREEN_BUCKET
export const NETWORK_CYCLE_INFRA_SUFFIX = '_cycle_infra'
export const AUTO_VALUE = 'auto'
export type AutoValue = typeof AUTO_VALUE

function forEachDataConfigs(config: ConfigFile, itterator: ({
  layer,
  data,
}: {
  layer: LayerType
  data: DataDefinition
}) => void | false): void {
  let stopClaim = false

  for (const n of config) {
    if (n.data.length > 0) {
      if (stopClaim) {
        break
      }

      for (const d of n.data) {
        if (itterator({
          layer: n.layer,
          data: d,
        }) === false) {
          stopClaim = true
          break
        }
      }
    }
  }
}

function findInData(configFile: ConfigFile, itterator: (data: DataDefinition) => boolean): DataDefinition | null {
  let data = null

  forEachDataConfigs(configFile, ({ data: d }) => {
    if (itterator(d)) {
      data = d
      return false
    }
  })

  return data
}

export const useCyclability = createSharedComposable((citySlug: Ref<string | null>, mapOptions?: Reactive<{
  zoom: number
  center?: LngLatLike
}>) => {
  const { translateFromData } = useLabelTranslation()

  const loading = ref(true)
  const configFile = shallowRef<ConfigFile | null>()

  const {
    geometryType,
    areasMetric,
    networkType,
    networkMetric,
  } = useReactiveParams({
    geometryType: { name: 'geometry', defaultValue: null },
    areasMetric: { name: 'geoMetric' },
    networkType: { name: 'network' },
    networkMetric: { name: 'networkMetric', defaultValue: null },
  })

  const availableGeometries = shallowRef<AvailableFilters<GeometryType | AutoValue>>([])
  const availableAreasMetrics = shallowRef<AvailableFilters>()
  const availableNetworks = shallowRef<AvailableFilters>([])
  const availableNetworkMetrics = shallowRef<AvailableFilters>()

  const areasConfig = shallowRef<ConfigFile<LayerTypes.AREAS>>([])
  const networksConfig = shallowRef<ConfigFile<LayerTypes.NETWORK>>([])

  const areaActiveData = shallowRef<DataDefinition | null>()
  const networkActiveData = shallowRef<DataDefinition | null>()

  const { translateIfExists } = useLabelTranslation()

  async function getConfigFile() {
    loading.value = true

    try {
      const results = await fetch(urlToFetch('/metrics.json'))
      configFile.value = await results.json() as ConfigFile<LayerType>
      prepareCityConfig(configFile.value)
    } catch (e) {
      console.error(e)
      reset()
    }

    loading.value = false
  }

  function isNetworkCycleInfra(networkType: string | null) {
    return networkType?.endsWith('_cycle_infra')
  }

  function cleanNetworkType(networkType: string | null) {
    return networkType?.replace('_cycle_infra', '')
  }

  function reset() {
    availableGeometries.value = []
    availableNetworks.value = []
    areasConfig.value = []
    networksConfig.value = []
    availableAreasMetrics.value = undefined
    availableNetworkMetrics.value = undefined
  }

  async function prepareCityConfig(configFile: ConfigFile<LayerType>) {
    reset()

    const availableGeometriesTmp: GeometryType[] = []
    const availableNetworksTmp: string[] = []

    const areas = configFile.filter((c): c is LayerDefinition<LayerTypes.AREAS> => c.layer === LayerTypes.AREAS)
    const networks = configFile.filter((c): c is LayerDefinition<LayerTypes.NETWORK> => c.layer === LayerTypes.NETWORK)

    forEachDataConfigs(areas, ({ data }) => {
      if (data.type === 'geometryType' && !availableGeometriesTmp.includes(data.name as GeometryType)) {
        availableGeometriesTmp.push(data.name as GeometryType)
      }
    })

    forEachDataConfigs(networks, ({ data }) => {
      if (data.type === 'networkType' && !availableNetworksTmp.includes(data.name)) {
        availableNetworksTmp.push(data.name)
      }
    })

    // save filtered configs
    areasConfig.value = areas
    networksConfig.value = networks

    // setup selected network value
    if (!networkType.value || !availableNetworksTmp.includes(networkType.value)) {
      networkType.value = availableNetworksTmp[0]
    }

    // setup selected geometry value
    const lastSelectedGeometry = geometryType.value
    const hasAuto = availableGeometriesTmp.length > 1

    if (
      lastSelectedGeometry === undefined
      || ((lastSelectedGeometry && !availableGeometriesTmp.includes(lastSelectedGeometry as GeometryType))
        && (hasAuto && lastSelectedGeometry !== AUTO_VALUE))
    ) {
      geometryType.value = availableGeometriesTmp[0]
    }

    // setup available filters
    availableGeometries.value = [
      ...availableGeometriesTmp.map(n => ({
        name: n as GeometryType,
        label: translateFromData('geometry', n),
      })),
    ]

    availableNetworks.value = availableNetworksTmp.reduce((acc, n) => {
      acc.push({
        name: n,
        label: translateIfExists(`cyclability.data.networkType.${n}`, n),
      }, {
        name: `${n}${NETWORK_CYCLE_INFRA_SUFFIX}`,
        label: translateIfExists(`cyclability.data.networkType.${n}${NETWORK_CYCLE_INFRA_SUFFIX}`, `${n}${NETWORK_CYCLE_INFRA_SUFFIX}`),
      })

      return acc
    }, [] as AvailableFilters)

    updateActiveData({
      geometryType: geometryType.value,
      networkType: networkType.value,
    })
  }

  function checkFileName<T = string | null>(data: DataDefinition, name: T) {
    return data.name === name
  }

  function updateActiveData({
    geometryType,
    networkType,
  }: {
    geometryType?: GeometryType | string | null
    networkType?: string | null
  }) {
    if (geometryType !== undefined) {
      if (geometryType === AUTO_VALUE) {
        const zoom = mapOptions?.zoom || 0

        if (zoom <= 12) {
          geometryType = GeometriesTypes.H3_8
        } else if (zoom <= 14) {
          geometryType = GeometriesTypes.H3_9
        } else {
          geometryType = GeometriesTypes.H3_10
        }
      }

      const ac = findInData(areasConfig.value, data => checkFileName(data, geometryType))

      if (ac) {
        areaActiveData.value = ac
        availableAreasMetrics.value = ac?.metrics.map(m => ({
          name: m.metric,
          label: translateIfExists(`cyclability.data.areas.${m.metric}`, m.metric),
        })) || []

        if (!areasMetric.value) {
          areasMetric.value = ac?.metrics[0].metric
        }
      }
    }

    if (networkType !== undefined) {
      const nc = findInData(networksConfig.value, f => checkFileName(f, cleanNetworkType(networkType)))

      if (nc) {
        networkActiveData.value = nc
        availableNetworkMetrics.value = nc.metrics.map(m => ({
          name: m.metric,
          label: translateIfExists(`cyclability.data.network.${m.metric}`, m.metric),
        })) || []
      }
    }
  }

  function urlToFetch(path: string) {
    return `${FILE_PATH_BASE}/${citySlug.value}/${path.indexOf('/') === 0 ? path.slice(1) : path}`
  }

  onMounted(() => {
    whenever(citySlug, getConfigFile, { immediate: true })
    whenever(networkType, networkType => updateActiveData({ networkType }))
    watch([geometryType, () => mapOptions?.zoom], ([geometryType]) => {
      if (geometryType) {
        updateActiveData({ geometryType })
      }
    })
  })

  return {
    loading,
    geometryType,
    networkType,
    networkMetric,
    areasMetric,
    availableGeometries,
    availableNetworks,
    areasConfig,
    networksConfig,
    areaActiveData,
    networkActiveData,
    availableAreasMetrics,
    availableNetworkMetrics,
    isNetworkCycleInfra,
    urlToFetch,
  }
})
