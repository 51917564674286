<script setup lang="ts">
import type { CyclabilityMapLayerProps } from '@/types/cyclability.types'
import type { CellsType } from '@/types/index.types'
import { GeometriesTypes } from '@/types/index.types'

export interface CyclabilityMapCellsGridProps extends CyclabilityMapLayerProps {
  cellsType?: CellsType
  tileSrc: string
}

defineOptions({
  name: 'CellsGrid',
})

const props = withDefaults(defineProps<CyclabilityMapCellsGridProps>(), {
  layerId: 'cells-grid',
  cellsType: GeometriesTypes.H3_10,
  metric: '',
})

const { classBreaks, metric } = toRefs(props)
const colorBreaks = useExpressionColorFromBreaks(classBreaks, metric, 'case')

const paintExpression = computed(() => {
  return {
    'fill-opacity': 0.45,
    'fill-color': colorBreaks.value,
    'fill-antialias': true,
    'fill-outline-color': 'transparent',
  }
})
</script>

<template>
  <MapLibreSourceTile
    :id="layerId"
    type="vector"
    :tiles="[tileSrc]"
    :layer-props="{
      'source-layer': 'metrics',
      'type': 'fill',
      'paint': paintExpression,
    }"
  />
</template>
