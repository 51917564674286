import type { CyclabilityNetworkRoadType } from '@/types/cyclability.types'

type OSMRoadType = string
type CityRoadType = string
export type RoadType = OSMRoadType | CityRoadType

export const useCyclabilityNetwork = createSharedComposable((citySlug: Ref<string | null>) => {
  const { networkActiveData, networkMetric, availableNetworkMetrics } = useCyclability(citySlug)
  const networkMetricActiveData = computed(() => {
    return networkActiveData.value?.metrics.find(m => m.metric === networkMetric.value)
  })

  whenever(availableNetworkMetrics, (availableNetworkMetrics) => {
    const lastValue = networkMetric.value

    if (availableNetworkMetrics.map(m => m.name).includes(lastValue as string)) {
      networkMetric.value = lastValue
    } else {
      networkMetric.value = null
    }
  }, { immediate: true })

  return {
    networkActiveData,
    networkMetricActiveData,
  }
})

type FilteredCyclabilityNetworkRoadTypes = [CyclabilityNetworkRoadType, number][]
const cyclabilityRoadTypesEntries = Object.entries(CyclabilityNetworkRoadTypes)

export const getCyclabilityRoadTypes: Record<string, (predicate?: any) => FilteredCyclabilityNetworkRoadTypes> = {
  getAllTypes: () => cyclabilityRoadTypesEntries.filter(([key]) => Number.isNaN(Number(key))) as FilteredCyclabilityNetworkRoadTypes,

  getSecondaryTypes: () => cyclabilityRoadTypesEntries.filter(([key]) => Number.isNaN(Number(key)))
    .filter(([_, value]) => Number(value) >= 5 && Number(value) <= 8) as FilteredCyclabilityNetworkRoadTypes,

  getCyclingInfraTypes: () => cyclabilityRoadTypesEntries.filter(([key]) => Number.isNaN(Number(key)))
    .filter(([_, value]) => Number(value) >= 1 && Number(value) <= 9) as FilteredCyclabilityNetworkRoadTypes,

  getNotCyclingInfraTypes: () => cyclabilityRoadTypesEntries.filter(([key]) => Number.isNaN(Number(key)))
    .filter(([_, value]) => Number(value) >= 9 && Number(value) <= cyclabilityRoadTypesEntries.length) as FilteredCyclabilityNetworkRoadTypes,

  filterTypes: (predicate: (value: number) => boolean) =>
    cyclabilityRoadTypesEntries.filter(([key]) => Number.isNaN(Number(key)))
      .filter(([_, value]) => predicate(Number(value))) as FilteredCyclabilityNetworkRoadTypes,
}
