import type { TimeMode } from '@/types/charts.types'
import { useDateTranslation } from '@/composables/translation'
import { REGIONS } from '@/utils/constants'
import { defineStore } from 'pinia'

export interface DateRange {
  start: Date
  end: Date
}

export interface ComparativeRange {
  start: Date
  end: Date
}

export const useFiltersStore = defineStore('filters', () => {
  const { t } = useI18n()
  const { translateRange } = useDateTranslation()

  function formatRange(range: DateRange) {
    return {
      start: formatDate(range.start, DATE_FORMAT),
      end: formatDate(range.end, DATE_FORMAT),
    }
  }

  const dateRange = ref<DateRange>(defaultDateRange())
  const dateRangeLimit = ref<DateRange>()
  const comparativeRange = ref<ComparativeRange | null>(null)
  const timeMode = ref<TimeMode>(TimeModes.DAY)
  const region = ref<string | null>(null)
  const country = ref<string | null>(null)
  const carPropulsion = ref<string[]>([])
  const carSize = ref<string[]>([])
  const carModel = ref<string[]>([])

  const regions = computed(() => (region.value ? [region.value] : null))

  const regionDetails = computed(() => REGIONS.find((item) => {
    const r = region.value || RegionsCodes.EU
    return item.code === r
  }))

  const dateRangeStr = computed(() => {
    return formatRange(dateRange.value)
  })

  const dateRangeFormated = computed(() => {
    const range = translateRange(dateRange.value)
    return `${range.start} - ${range.end}`
  })

  const dateRangeHumanFormatted = computed(() => t('dates.daterange_formated_human', translateRange(dateRange.value)))

  const comparativeRangeStr = computed(() =>
    comparativeRange.value
      ? formatRange(comparativeRange.value)
      : null)

  const comparativeFormated = computed(() => {
    if (!comparativeRange.value) {
      return null
    }

    const range = translateRange(comparativeRange.value)
    return `${range.start} - ${range.end}`
  })

  const isSpecsFiltered = computed(() => {
    return (
      carPropulsion.value.length > 0
      || carSize.value.length > 0
      || carModel.value.length > 0
    )
  })

  function $reset(defaultRegion: string | null = null) {
    dateRange.value = defaultDateRange()
    dateRangeLimit.value = undefined
    comparativeRange.value = null
    timeMode.value = TimeModes.DAY
    setRegion(defaultRegion)
    resetFiltersSpecs()
  }

  function setRegion(newRegion: string | null) {
    region.value = newRegion
    country.value = null
  }

  function resetFiltersSpecs() {
    carPropulsion.value = []
    carSize.value = []
    carModel.value = []
  }

  return {
    dateRange,
    dateRangeLimit,
    comparativeRange,
    timeMode,
    region,
    country,
    carPropulsion,
    carSize,
    carModel,
    regions,
    regionDetails,
    dateRangeStr,
    dateRangeFormated,
    dateRangeHumanFormatted,
    comparativeRangeStr,
    comparativeFormated,
    isSpecsFiltered,
    $reset,
    setRegion,
    resetFiltersSpecs,
  }
})

// make sure to pass the right store definition, `useFiltersStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFiltersStore, import.meta.hot))
}
