<script setup lang="ts">
import type { CyclabilityMapLayerProps } from '@/types/cyclability.types'

export interface CyclabilityMapNetworkProps extends CyclabilityMapLayerProps {
  tileSrc: string
  isCycleInfra: boolean
}

defineOptions({
  name: 'CityNetwork',
})

const props = withDefaults(defineProps<CyclabilityMapNetworkProps>(), {
  layerId: 'city-network',
  metric: 'road',
})

const { classBreaks, metric } = toRefs(props)
const paintColor = useExpressionColorFromBreaks(classBreaks, computed(() => metric.value === 'road' ? 'fluctuo_classification_id' : metric.value), 'case')

const SecondaryRoadTypes = getCyclabilityRoadTypes.getSecondaryTypes()
const CycleInfraTypes = getCyclabilityRoadTypes.getCyclingInfraTypes()
const NotCyclingInfraTypes = getCyclabilityRoadTypes.getNotCyclingInfraTypes()
</script>

<template>
  <MapLibreSourceTile
    :id="layerId"
    type="vector"
    :tiles="[tileSrc]"
    :layer-props="{
      'source-layer': 'metrics',
      'type': 'line',
      'paint': {
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10, 1,
          15, [
            'case',
            ['in', ['get', 'fluctuo_classification_id'], ['literal', SecondaryRoadTypes.map(([_, value]) => value)]],
            2,
            ['in', ['get', 'fluctuo_classification_id'], ['literal', NotCyclingInfraTypes.map(([_, value]) => value)]],
            3,
            4,
          ],
        ],
        'line-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          9, [
            'case',
            ['in', ['get', 'fluctuo_classification_id'], ['literal', SecondaryRoadTypes.map(([_, value]) => value)]],
            0,
            ['in', ['get', 'fluctuo_classification_id'], ['literal', NotCyclingInfraTypes.map(([_, value]) => value)]],
            0.1,
            1,
          ],
          12, [
            'case',
            ['in', ['get', 'fluctuo_classification_id'], ['literal', SecondaryRoadTypes.map(([_, value]) => value)]],
            0.3,
            ['in', ['get', 'fluctuo_classification_id'], ['literal', NotCyclingInfraTypes.map(([_, value]) => value)]],
            0.5,
            0.73,
          ],
          13, 0.73,
        ],
        'line-color': paintColor || '#576067',
      },
      'layout': {
        'line-sort-key': ['-', ['get', 'fluctuo_classification_id']],
      },
      'filter': isCycleInfra ? ['in', ['get', 'fluctuo_classification_id'], ['literal', CycleInfraTypes.map(([_, value]) => value)]] : ['all', true],
    }"
  />
</template>
