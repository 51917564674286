import type { DateRange } from '@/stores/filters'
import type { Provider } from '@/stores/provider'
import { useFiltersStore } from '@/stores/filters'
import { REGIONS } from '@/utils/constants'
import {
  differenceInDays,
  endOfDay,
  parse,
  startOfDay,
  subDays,
  subMonths,
} from 'date-fns'
import { watch } from 'vue'
import { usePreferences } from './preferences'

export function useDateRangeFilter() {
  const gtm = useGtm()
  const { t } = useI18n()
  const filtersStore = useFiltersStore()

  const end = endOfDay(subDays(now, 1))
  const relativeRanges = filtersStore.dateRangeLimit
    ? {}
    : {
        last7days: {
          name: t('Last 7 days'),
          period: {
            start: startOfDay(subDays(now, 7)),
            end,
          },
        },
        last30days: {
          name: t('Last 30 days'),
          period: {
            start: startOfDay(subDays(now, 30)),
            end,
          },
        },
        last2months: {
          name: t('Last 2 months'),
          period: {
            start: startOfDay(subMonths(now, 2)),
            end,
          },
        },
        last4months: {
          name: t('Last 4 months'),
          period: {
            start: startOfDay(subMonths(now, 4)),
            end,
          },
        },
        last6months: {
          name: t('Last 6 months'),
          period: {
            start: startOfDay(subMonths(now, 6)),
            end,
          },
        },
        lastYear: {
          name: t('Last 12 months'),
          period: {
            start: startOfDay(subMonths(now, 12)),
            end,
          },
        },
      }

  const relativeComparatives = [
    {
      name: t('Previous period'),
      period: (dateRange: DateRange) => {
        const { start, end } = dateRange
        const nbrDays = differenceInDays(end, start)

        return {
          start: startOfDay(subDays(start, nbrDays + 1)),
          end: endOfDay(subDays(end, nbrDays + 1)),
        }
      },
    },
    {
      name: t('Same period last year'),
      period: (dateRange: DateRange) => {
        const { start, end } = dateRange
        return {
          start: startOfDay(subMonths(start, 12)),
          end: endOfDay(subMonths(end, 12)),
        }
      },
    },
  ]

  const dateRange = computed({
    get: () => ({ ...filtersStore.dateRange }),
    set(value) {
      if (gtm?.enabled()) {
        if (value?.start) {
          gtm.trackEvent({
            event: 'update_date_range',
            category: 'ui',
            label: 'start_date',
            value: value.start instanceof Date ? value.start.getTime() : parse(value.start, DATE_FORMAT, new Date()).getTime(),
          })
        }

        if (value?.end) {
          gtm.trackEvent({
            event: 'update_date_range',
            category: 'ui',
            label: 'end_date',
            value: value.end instanceof Date ? value.end.getTime() : parse(value.end, DATE_FORMAT, new Date()).getTime(),
          })
        }
      }

      filtersStore.$patch({
        dateRange: {
          start: startOfDay(value.start),
          end: endOfDay(value.end),
        },
      })
    },
  })

  const comparativeRange = computed({
    get: () =>
      (filtersStore.comparativeRange && {
        ...filtersStore.comparativeRange,
      })
      || null,
    set(value) {
      if (value) {
        filtersStore.comparativeRange = {
          start: startOfDay(value.start),
          end: endOfDay(value.end),
        }
      } else {
        filtersStore.comparativeRange = null
      }
    },
  })

  function toggleComparativeRange(dateRange?: DateRange) {
    if (!dateRange || get(comparativeRange)) {
      filtersStore.comparativeRange = null
    } else {
      filtersStore.comparativeRange = {
        ...relativeComparatives[0].period(dateRange),
      }
    }
  }

  // reset comparative range when is not used on the page
  onBeforeRouteLeave((to) => {
    const isCompare = to.meta.comparativeRange

    if (!isCompare && get(comparativeRange)) {
      toggleComparativeRange()
    }
  })

  return {
    relativeRanges,
    relativeComparatives,
    dateRange,
    comparativeRange,
    toggleComparativeRange,
  }
}

export function useFilterRegion(provider: Ref<Provider>) {
  const { unlockedRegions } = storeToRefs(useUserStore())
  const filtersStore = useFiltersStore()

  const userRegions = computed(() =>
    REGIONS.filter(
      r => r.code === null || unlockedRegions.value.includes(r.code),
    ),
  )

  const providerRegions = computed(() =>
    getRegionsFromDataFiltered(get(provider), get(userRegions)),
  )

  const regionIconName = computed(
    () => `world-${((filtersStore.region || RegionsCodes.EU) as string)}`.toLowerCase(),
  )

  return {
    userRegions,
    providerRegions,
    regionIconName,
  }
}

export function useFilterHighlight(id: Ref<string>, isOpen: Ref<boolean>) {
  const { isMobile } = useMobile()
  const navigationStore = useNavigationStore()
  const { ready, start } = useTimeout(500, { controls: true })

  const isHighlighted = ref(false)

  watch(ready, (ready) => {
    set(isHighlighted, !ready)
  })

  if (get(id)) {
    globalEmitter.on(`highlight:${get(id)}`, () => {
      start()
    })
    globalEmitter.on(`open:${get(id)}`, () => {
      start()

      if (isMobile) {
        navigationStore.toggleSettings()
      }

      if (isOpen) {
        isOpen.value = true
      }
    })
  }

  // TODO check if is ok in composition api
  onBeforeUnmount(() => {
    globalEmitter.off(`highlight:${get(id)}`)
    globalEmitter.off(`open:${get(id)}`)
  })

  return {
    isHighlighted,
  }
}

export function useFiltersFromPreferences(board: string) {
  const { getPref, setPref } = usePreferences()
  const filtersStore = useFiltersStore()

  // Liste des propriétés à surveiller
  const filterProps = [
    'timeMode',
    'region',
    'country',
    'carPropulsion',
    'carSize',
    'carModel',
  ] as const

  // Initialisation depuis les préférences
  filterProps.forEach((prop) => {
    const value = getPref(`boards.${board}.${prop}`)
    if (value !== undefined) {
      filtersStore[prop] = value
    }
  })

  // Surveillance des changements
  filterProps.forEach((prop) => {
    watch(
      () => filtersStore[prop],
      (newValue) => {
        setPref(`boards.${board}.${prop}`, newValue)
      },
      { deep: true },
    )
  })
}

export function useResetFilters(emit: (event: string, ...args: any[]) => void) {
  const filtersStore = useFiltersStore()

  function onReset() {
    filtersStore.$reset()
    globalEmitter.emit('filters:reset')
    if (emit) {
      emit('reset')
    }
  }

  return {
    onReset,
  }
}
