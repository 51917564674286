<script setup>
defineOptions({
  name: 'ProviderCarsComparison',
})

const { t } = useI18n()
const { translateFromData } = useLabelTranslation()
const filtersStore = useFiltersStore()

const sortBy = ref(VEHICLES)
const limited = ref(true)
const limit = 5

function getPercent(value, total) {
  return (value * 100) / total
}

/**
 * @typedef {object} ModelData
 * @property {string} name
 * @property {ModelDataValues | ModelDataValuesWithoutTrips | null} [filtered]
 * @property {ModelDataValues | ModelDataValuesWithoutTrips | null} [others]
 * @property {ModelDataValues} total
 */

/**
 * @typedef {object} ModelDataValues
 * @property {number} vehicles
 * @property {number} trips
 * @property {number} tvd
 */

/**
 * @typedef {object} ModelDataValuesWithoutTrips
 * @property {number} vehicles
 */

/**
 * @param {ModelData} model
 * @param {string} type
 */
function getPercentChartData(model, type = 'vehicles') {
  const label = translateFromData('carModel', model.name)
  const fields = ['filtered', 'others', 'total']
  const { filtered, others, total } = Object.entries(model).reduce(
    (acc, [key, value]) => {
      if (fields.includes(key)) {
        acc[key] = value?.[type] == null ? null : value[type]
      }

      return acc
    },
    { filtered: null, others: null, total: null },
  )

  if (filtered == null && others == null) {
    // no filtered and no others
    return null
  }

  if (filtered == null) {
    return []
  }

  const filteredData = {
    label,
    percent: getPercent(filtered, total),
    value: filtered,
    color: PALETTE_COLORS_VEHICLES.car,
  }

  if (!others) {
    const customOthers = total - filtered

    return [
      filteredData,
      {
        label: t('N/A'),
        percent: getPercent(customOthers, total),
        value: customOthers || 0,
        color: '#fff',
      },
    ]
  }

  return [
    filteredData,
    {
      label: filtersStore.isSpecsFiltered ? t('other_filtered_car_models') : t('Other models'),
      percent: getPercent(others, total),
      value: others || 0,
      color: DEFAULT_COLOR,
    },
  ]
}

function sortAndLimit(list) {
  list = list.slice()

  if (get(limited) && list.length > limit) {
    return list.sort(sortByAttr(`filtered.${get(sortBy)}`)).slice(0, limit)
  }

  return list.sort(sortByAttr(`filtered.${get(sortBy)}`))
}
</script>

<template>
  <MetricCard
    kpi="providerCarsComparison"
    :as-mode="VEHICLE_TYPE_CAR"
    :used-filters="['dateRange', 'regions', 'country', 'filterBySpecs']"
  >
    <template #title>
      {{ t('Car model performance list') }}
    </template>
    <template #range-subtitle="{ range }">
      {{ t('AVG') }} {{ range }}
    </template>

    <template #right>
      <DSelectPicker
        v-model="sortBy"
        :options="[
          { text: t('Vehicles'), value: VEHICLES },
          { text: t('Trips'), value: TRIPS },
          { text: t('TVD'), value: TVD },
        ]"
        :label="t('Sort by')"
        align="right"
      />
    </template>

    <template #default="{ data }">
      <div class="flex gap-4 lg:gap-8 text-gray-400 uppercase text-xs">
        <p class="w-2/12">
          {{ t('Model') }}
        </p>
        <p class="w-4/12">
          {{ t('Vehicles') }}
        </p>
        <p class="w-4/12">
          {{ t('Trips') }}
        </p>
        <p class="w-1/12">
          <DHelpTooltip :help="t('Trips per vehicle per day')">
            {{ t('TVD') }}
          </DHelpTooltip>
        </p>
      </div>

      <div
        v-for="model in sortAndLimit(data.models)"
        :key="model.name"
        class="flex gap-4 lg:gap-8 mt-4"
      >
        <div class="w-2/12 text-sm">
          {{ translateFromData('carModel', model.name) }}
        </div>
        <div class="w-4/12">
          <PercentBarMetric
            :data="getPercentChartData(model)"
            :legend="false"
            :min-width="1"
            height="h-6"
          >
            <template #tooltip="{ value, percent }">
              <p class="whitespace-nowrap">
                {{ `${t('n_cars', { n: formatNumber(value) }, value)} (${formatPercent(percent)})` }}
              </p>
            </template>
          </PercentBarMetric>
        </div>
        <div class="w-4/12">
          <PercentBarMetric
            :data="getPercentChartData(model, 'trips')"
            :legend="false"
            :min-width="1"
            height="h-6"
          >
            <template #tooltip="{ value, percent }">
              <p class="whitespace-nowrap">
                {{ `${t('n_trips', { n: formatNumber(value) }, value)} (${formatPercent(percent)})` }}
              </p>
            </template>
          </PercentBarMetric>
        </div>
        <div class="w-1/12 flex justify-between gap-2">
          <template v-if="model.filtered?.tvd == null && model.others?.tvd == null">
            <span class="text-grey-400 text-sm">{{ t('N/A') }}</span>
          </template>
          <template v-else>
            <span class="text-red-500 font-extrabold">{{
              model.filtered?.tvd == null
                ? '-'
                : formatAverage(model.filtered.tvd)
            }}</span>
            <span class="text-grey-400">{{
              model.others?.tvd == null ? '-' : formatAverage(model.others.tvd)
            }}</span>
          </template>
        </div>
      </div>

      <div
        v-if="data.models.length > limit"
        class="mt-4"
      >
        <DLink @click="limited = !limited">
          {{ limited ? t('Display more models') : t('Display less models') }}
          <DIcon
            size="sm"
            :path="`chevron-${limited ? 'down' : 'up'}`"
          />
        </DLink>
      </div>

      <SimpleLegend
        class="justify-center"
        :class="{
          'mt-8': !(data.models.length > limit),
          'mt-4': data.models.length > limit,
        }"
        :items="[
          {
            name: t('This model'),
            color: PALETTE_COLORS_VEHICLES.car,
          },
          {
            name: t('Others model'),
            color: DEFAULT_COLOR,
          },
        ]"
      />
    </template>
  </MetricCard>
</template>
