<script setup lang="ts">
import {
  endOfDay,
  startOfDay,
  subMonths,
} from 'date-fns'

defineOptions({
  name: 'CityCyclabilityPassagesMetric',
})

interface DataItem {
  ecoCounter: number
}

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())

const { writeCache } = useWriteCacheForMetric('cityCyclabilityPassages')
const { dateRange: defaultDateRange, dateRangeLimit, getDataInRange, getLastDateRange } = useBarcelonaData()
const { translateRange } = useDateTranslation()

const dateRange = computed(() => {
  if (!dateRangeLimit.value) {
    return defaultDateRange.value
  }

  return {
    start: startOfDay(subMonths(dateRangeLimit.value?.end, 2)),
    end: endOfDay(dateRangeLimit.value?.end),
  }
})

const lastDateRange = computed(() => getLastDateRange(dateRange.value))

function updateCachedData(dataInRange: any, comparedDataInRange: any) {
  const avgLength = (dataInRange?.length || 1) / 2
  const avgLengthLast = (comparedDataInRange?.length || 1) / 2

  const avgDaily = dataInRange?.reduce((acc: number, item: DataItem) => acc + item.ecoCounter, 0) / avgLength
  const avgDailyLast = comparedDataInRange?.reduce((acc: number, item: DataItem) => acc + item.ecoCounter, 0) / avgLengthLast

  const dailyTrendPercent = (avgDaily - avgDailyLast) / avgDaily * 100
  const dailyTrendDirection = dailyTrendPercent > 0 ? Directions.UP : Directions.DOWN
  const dailyTrendPositive = dailyTrendPercent > 0

  const maximum = dataInRange?.reduce((acc: number, item: DataItem) => acc < item.ecoCounter ? item.ecoCounter : acc, 0)
  const maximumLast = comparedDataInRange?.reduce((acc: number, item: DataItem) => acc < item.ecoCounter ? item.ecoCounter : acc, 0)

  const maximumTrendPercent = (maximum - maximumLast) / maximum * 100
  const maximumTrendDirection = maximumTrendPercent > 0 ? Directions.UP : Directions.DOWN
  const maximumTrendPositive = maximumTrendPercent > 0

  const total = dataInRange?.reduce((acc: number, item: DataItem) => acc + item.ecoCounter, 0)
  const totalLast = comparedDataInRange?.reduce((acc: number, item: DataItem) => acc + item.ecoCounter, 0)

  const totalTrendPercent = (total - totalLast) / total * 100
  const totalTrendDirection = totalTrendPercent > 0 ? Directions.UP : Directions.DOWN
  const totalTrendPositive = totalTrendPercent > 0

  writeCache({
    city: city.value?.name,
  }, {
    date: dateRange.value.start.toString(),
    daily: {
      __typename: 'Percent',
      value: Math.round(avgDaily),
      trend: {
        percent: Number(dailyTrendPercent.toFixed(2)),
        direction: dailyTrendDirection,
        positive: dailyTrendPositive,
      },
    },
    maximum: {
      __typename: 'Percent',
      value: Math.round(maximum),
      trend: {
        percent: Number(maximumTrendPercent.toFixed(2)),
        direction: maximumTrendDirection,
        positive: maximumTrendPositive,
      },
    },
    total: {
      __typename: 'Percent',
      value: Math.round(total),
      trend: {
        percent: Number(totalTrendPercent.toFixed(2)),
        direction: totalTrendDirection,
        positive: totalTrendPositive,
      },
    },
  })
}

watch([dateRange, city], () => {
  if (!dateRange.value) {
    return
  }

  const dataInRange = getDataInRange(dateRange.value)
  const comparedDataInRange = getDataInRange(lastDateRange.value)

  updateCachedData(dataInRange, comparedDataInRange)
}, { immediate: true })
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityPassages"
    :used-filters="[]"
  >
    <template #title>
      {{ t('cyclability.cycling_passages.title') }}
    </template>

    <template #subtitle>
      {{ t('dates.daterange_formated_human', translateRange(dateRange)) }} - VS {{ t('dates.daterange_formated_human', translateRange(lastDateRange)) }} - {{ t('from') }} Eco-Compteur
    </template>

    <template #default="{ data }">
      <div class="flex flex-col xl:flex-row gap-4 justify-between">
        <div>
          <p class="text-sm">
            {{ t('cyclability.cycling_passages.daily') }}
          </p>
          <TrendMetric :data="data.daily" />
        </div>
        <div>
          <p>{{ t('cyclability.cycling_passages.maximum') }}</p>
          <TrendMetric :data="data.maximum" />
        </div>
        <div>
          <p>{{ t('cyclability.cycling_passages.total') }}</p>
          <TrendMetric :data="data.total" />
        </div>
      </div>
    </template>
  </MetricCard>
</template>
