<script setup lang="ts">
import type { SeriePercentValue } from '@/types/charts.types'

defineOptions({
  name: 'CityCarsComparison',
})

const { t } = useI18n()
const { translateLabel } = useLabelTranslation()
const filtersStore = useFiltersStore()

const sortBy = ref(VEHICLES)
const limited = ref(true)
const limit = 3

function getPercent(value: number, total: number) {
  if (total === 0) {
    return 0
  }

  return (value * 100) / total
}

interface ProviderData {
  slug: string
  filtered: ProviderDataValues | undefined | null
  others: ProviderDataValues | undefined | null
  total: ProviderDataValues
}

interface ProviderDataValues {
  vehicles: number
  trips: number
  tvd?: number
}

function getPercentChartData(provider: ProviderData, type = 'vehicles'): SeriePercentValue[] {
  const fields = ['filtered', 'others', 'total']
  const { filtered, others, total } = Object.entries(provider).reduce<{ [key: string]: number | null }>(
    (acc, [key, value]) => {
      if (fields.includes(key)) {
        acc[key] = value?.[type] == null ? null : value[type]
      }

      return acc
    },
    {},
  )

  if (filtered == null || (filtered == null && others == null)) {
    // no filtered and no others
    return []
  }

  const filteredData = {
    label: t('Filtered cars'),
    percent: getPercent(filtered, total || 0),
    value: filtered,
    color: PALETTE_COLORS_VEHICLES.car,
  }

  if (!others) {
    const customOthers = (total || 0) - filtered

    return [
      filteredData,
      {
        label: t('N/A'),
        percent: getPercent(customOthers, total || 0),
        value: customOthers || 0,
        color: '#fff',
      },
    ]
  }

  return [
    filteredData,
    {
      label: t('Other cars'),
      percent: getPercent(others, total || 0),
      value: others || 0,
      color: DEFAULT_COLOR,
    },
  ]
}

function sortAndLimit(list: ProviderData[]) {
  list = list.slice()

  if (get(limited) && list.length > limit) {
    return list.sort(sortByAttr(`filtered.${get(sortBy)}`)).slice(0, limit)
  }

  return list.sort(sortByAttr(`filtered.${get(sortBy)}`))
}
</script>

<template>
  <MetricCard
    kpi="cityCarsComparison"
    :as-mode="VEHICLE_TYPE_CAR"
    :used-filters="['dateRange', 'filterBySpecs']"
  >
    <template #title>
      {{ t('Car operator performance list') }}
    </template>
    <template #range-subtitle="{ range }">
      {{ t('AVG') }} {{ range }}
    </template>
    <template #right>
      <DSelectPicker
        v-model="sortBy"
        :options="[
          { label: t('Vehicles'), value: VEHICLES },
          { label: t('Trips'), value: TRIPS },
          { label: t('TVD'), value: TVD },
        ]"
        :label="t('Sort by')"
        align="right"
      />
    </template>

    <template #default="{ data }">
      <div v-if="!filtersStore.isSpecsFiltered">
        <p class="text-center text-grey-400">
          <DIcon path="help" />
        </p>
        <p class="text-center text-grey-400">
          {{ t('choose_at_least_one_tag_to_display_cars_data') }}
        </p>
      </div>
      <template v-else>
        <div class="flex gap-4 lg:gap-8 text-gray-400 uppercase text-xs">
          <p class="w-2/12">
            {{ t('Operator') }}
          </p>
          <p class="w-4/12">
            {{ t('Vehicles') }}
          </p>
          <p class="w-4/12">
            {{ t('Trips') }}
          </p>
          <p class="w-1/12">
            <DHelpTooltip :help="t('Trips per vehicle per day')">
              {{ t('TVD') }}
            </DHelpTooltip>
          </p>
        </div>

        <div
          v-for="provider in sortAndLimit(data.providers)"
          :key="provider.slug"
          class="flex gap-4 lg:gap-8 mt-4"
        >
          <div class="w-2/12 text-sm">
            {{ translateLabel(provider.slug, DimensionTypes.PROVIDER) }}
          </div>
          <div class="w-4/12">
            <PercentBarMetric
              :data="getPercentChartData(provider)"
              :legend="false"
              :min-width="2"
              height="h-6"
            >
              <template #tooltip="serie">
                <p
                  v-if="'value' in serie"
                  class="whitespace-nowrap"
                >
                  {{ `${t('n_cars', { n: formatNumber(serie.value) }, serie.value)} (${formatPercent(serie.percent)})` }}
                </p>
                <p
                  v-else
                  class="whitespace-nowrap"
                >
                  {{ `${t('n_cars', { n: formatPercent(serie.percent) }, serie.percent)}` }}
                </p>
              </template>
            </PercentBarMetric>
          </div>
          <div class="w-4/12">
            <PercentBarMetric
              :data="getPercentChartData(provider, 'trips')"
              :legend="false"
              :min-width="2"
              height="h-6"
            >
              <template #tooltip="serie">
                <p
                  v-if="'value' in serie"
                  class="whitespace-nowrap"
                >
                  {{ `${t('n_trips', { n: formatNumber(serie.value) }, serie.value)} (${formatPercent(serie.percent)})` }}
                </p>
                <p
                  v-else
                  class="whitespace-nowrap"
                >
                  {{ `${t('n_trips', { n: formatPercent(serie.percent) }, serie.percent)}` }}
                </p>
              </template>
            </PercentBarMetric>
          </div>

          <div class="w-1/12 flex justify-between gap-2">
            <template v-if="provider.filtered?.tvd == null && provider.others?.tvd == null">
              <span class="text-grey-300 text-sm text-center">{{
                t('N/A')
              }}</span>
            </template>
            <template v-else>
              <span class="text-red-500 font-extrabold">{{
                provider.filtered?.tvd == null
                  ? '-'
                  : formatAverage(provider.filtered.tvd)
              }}</span>
              <span class="text-grey-400">{{
                provider.others?.tvd == null
                  ? '-'
                  : formatAverage(provider.others.tvd)
              }}</span>
            </template>
          </div>
        </div>

        <div
          v-if="data.providers.length > limit"
          class="mt-4"
        >
          <DLink @click="limited = !limited">
            {{
              limited
                ? t('Display more operators')
                : t('Display less operators')
            }}
            <DIcon
              size="sm"
              :path="`chevron-${limited ? 'down' : 'up'}`"
            />
          </DLink>
        </div>

        <SimpleLegend
          class="justify-center"
          :class="{
            'mt-8': !(data.providers.length > limit),
            'mt-4': data.providers.length > limit,
          }"
          :items="[
            {
              name: t('Filtered cars'),
              color: PALETTE_COLORS_VEHICLES.car,
            },
            {
              name: t('Others cars'),
              color: DEFAULT_COLOR,
            },
          ]"
        />
      </template>
    </template>
  </MetricCard>
</template>
