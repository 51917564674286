<script setup lang="ts">
import {
  endOfDay,
  startOfDay,
  subMonths,
} from 'date-fns'

defineOptions({
  name: 'CityCyclabilityBikesPerformance',
})

interface DataItem {
  freeFloatingVehicles: number
  stationBasedVehicles: number
  freeFloatingTrips: number
  stationBasedTrips: number
}

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())
const { providerNameBySlug } = useProviderName()
const { writeCache } = useWriteCacheForMetric('cityCyclabilityBikesPerformance')
const { dateRange: defaultDateRange, dateRangeLimit, getDataInRange, getLastDateRange } = useBarcelonaData()
const { translateRange } = useDateTranslation()

const dateRange = computed(() => {
  if (!dateRangeLimit.value) {
    return defaultDateRange.value
  }

  return {
    start: startOfDay(subMonths(dateRangeLimit.value?.end, 2)),
    end: endOfDay(dateRangeLimit.value?.end),
  }
})

const lastDateRange = computed(() => getLastDateRange(dateRange.value))

function updateCachedData(dataInRange: any, comparedDataInRange: any) {
  const avgLength = (dataInRange?.length || 1) / 2
  const avgLengthLast = (comparedDataInRange?.length || 1) / 2

  // Calculate daily averages for fleet
  const fleet = dataInRange?.reduce((acc: number, item: DataItem) => acc + item.freeFloatingVehicles, 0) / avgLength
  const station = dataInRange?.reduce((acc: number, item: DataItem) => acc + item.stationBasedVehicles, 0) / avgLength
  const totalAvgFleet = fleet + station

  // Keep total trips
  const trips = dataInRange?.reduce((acc: number, item: DataItem) => acc + item.freeFloatingTrips, 0) / avgLength
  const stationTrips = dataInRange?.reduce((acc: number, item: DataItem) => acc + item.stationBasedTrips, 0) / avgLength
  const totalAvgTrips = trips + stationTrips

  // Calculate daily averages for previous period fleet
  const fleetLast = comparedDataInRange?.reduce((acc: number, item: DataItem) => acc + item.freeFloatingVehicles, 0) / avgLengthLast
  const stationLast = comparedDataInRange?.reduce((acc: number, item: DataItem) => acc + item.stationBasedVehicles, 0) / avgLengthLast
  const totalAvgFleetLast = fleetLast + stationLast
  // Keep total trips for previous period
  const tripsLast = comparedDataInRange?.reduce((acc: number, item: DataItem) => acc + item.freeFloatingTrips, 0) / avgLengthLast
  const stationTripsLast = comparedDataInRange?.reduce((acc: number, item: DataItem) => acc + item.stationBasedTrips, 0) / avgLengthLast
  const totalAvgTripsLast = tripsLast + stationTripsLast

  const fleetTrendPercent = (totalAvgFleet - totalAvgFleetLast) / totalAvgFleetLast * 100
  const tripsTrendPercent = (totalAvgTrips - totalAvgTripsLast) / totalAvgTripsLast * 100

  const fleetTrendDirection = fleetTrendPercent > 0 ? Directions.UP : Directions.DOWN
  const tripsTrendDirection = tripsTrendPercent > 0 ? Directions.UP : Directions.DOWN

  const fleetTrendPositive = fleetTrendPercent > 0
  const tripsTrendPositive = tripsTrendPercent > 0

  const fleetTrend = {
    percent: Number(fleetTrendPercent.toFixed(2)),
    direction: fleetTrendDirection,
    positive: fleetTrendPositive,
  }

  const tripsTrend = {
    percent: Number(tripsTrendPercent.toFixed(2)),
    direction: tripsTrendDirection,
    positive: tripsTrendPositive,
  }

  writeCache({
    city: city.value?.name,
    dateRange: dateRange.value,
  }, {
    fleet: {
      total: {
        __typename: 'Percent',
        value: Math.round(totalAvgFleet),
        trend: fleetTrend,
      },
      vehicleTypes: {
        BIKE: Math.round(fleet),
        STATION: Math.round(station),
      },
      providers: {
        BIKE: ['ecooltra'],
        STATION: ['bicing', 'nextbike', 'donkey'],
      },
    },
    trips: {
      total: {
        __typename: 'Percent',
        value: Math.round(totalAvgTrips),
        trend: tripsTrend,
      },
      vehicleTypes: {
        BIKE: Math.round(trips),
        STATION: Math.round(stationTrips),
      },
      providers: {
        BIKE: ['ecooltra'],
        STATION: ['bicing', 'nextbike', 'donkey'],
      },
    },
  })
}

function vehicleTypesTitles(type: 'fleet' | 'trips', data: any) {
  const { BIKE, STATION } = data[type].providers

  return {
    BIKE: t('cyclability.bikes_performance.bike') + (BIKE.length > 0 ? ` (${BIKE.map(providerNameBySlug).join(', ')})` : ''),
    STATION: t('cyclability.bikes_performance.station') + (STATION.length > 0 ? ` (${STATION.map(providerNameBySlug).join(', ')})` : ''),
  }
}

watch([dateRange, city], () => {
  if (!dateRange.value) {
    return
  }

  const dataInRange = getDataInRange(dateRange.value)
  const comparedDataInRange = getDataInRange(lastDateRange.value)

  updateCachedData(dataInRange, comparedDataInRange)
}, { immediate: true })
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityBikesPerformance"
    :used-filters="[]"
  >
    <template #title>
      {{ t('cyclability.bikes_performance.title') }}
    </template>

    <template #subtitle>
      {{ t('dates.daterange_formated_human', translateRange(dateRange)) }} - VS {{ t('dates.daterange_formated_human', translateRange(lastDateRange)) }}
    </template>

    <template #default="{ data }">
      <div class="flex flex-col xl:flex-row gap-4 justify-between">
        <div>
          <p class="text-sm">
            {{ t('cyclability.bikes_performance.avg_daily_fleet') }}
          </p>
          <TrendMetricWithVehiclesTypes
            :data="data.fleet.total"
            :vehicles-types="data.fleet.vehicleTypes"
            :accept="['BIKE', 'STATION']"
            :titles="vehicleTypesTitles('fleet', data)"
          />
        </div>
        <div>
          <p>{{ t('cyclability.bikes_performance.avg_daily_trips') }}</p>
          <TrendMetricWithVehiclesTypes
            :data="data.trips.total"
            :vehicles-types="data.trips.vehicleTypes"
            :accept="['BIKE', 'STATION']"
            :titles="vehicleTypesTitles('trips', data)"
          />
        </div>
      </div>
    </template>
  </MetricCard>
</template>
