<script setup lang="ts">
import type { Map } from 'maplibre-gl'
import { ScaleControl } from 'maplibre-gl'

defineOptions({
  name: 'MapLibreScaleControl',
})

const map = inject<Ref<Map | undefined>>('map', ref())
const locale = inject<Ref<string | null>>('locale', ref(null))

let scale: ScaleControl | undefined

function getScaleUnit(): 'metric' | 'imperial' {
  const imperialLocales = ['en-US', 'my-MM', 'lr', 'mm']
  const userLocale = locale.value || 'en'
  return imperialLocales.some(locale => userLocale.startsWith(locale)) ? 'imperial' : 'metric'
}

whenever(map, (map) => {
  if (scale) {
    map.removeControl(scale)
  }

  scale = new ScaleControl({
    maxWidth: 100,
    unit: locale.value ? getScaleUnit() : 'metric',
  })

  map.addControl(scale, 'bottom-right')
}, { immediate: true })

onBeforeUnmount(() => {
  if (scale) {
    map.value?.removeControl(scale)
    scale = undefined
  }
})
</script>

<template>
  <slot />
</template>

<style>
.maplibregl-ctrl-bottom-right .maplibregl-ctrl.maplibregl-ctrl-scale {
  background-color: transparent;
  margin: 0 90px 20px 0;
  border-left: 0;
  border-right: 0;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    border-left: 2px solid #333;
    border-right: 2px solid #333;
  }
}
</style>
