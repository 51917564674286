<script setup lang="ts">
import type { Layer } from '@/components/MapLibre/MapLibreSource/MapLibreSourceGeojson.vue'
import type { ClassBreaks } from '@/composables/classBreaks'
import type { RoadMetric } from '@/composables/cyclability/cyclabilityRoads'
import type { CyclabilityMapLayerProps } from '@/types/cyclability.types'

export interface CyclabilityMapSegmentsProps extends CyclabilityMapLayerProps {
  geojson: GeoJSON.FeatureCollection<GeoJSON.LineString>
  metric: RoadMetric
  selected?: string[]
  outline?: {
    classBreaks: ClassBreaks
    labels: string[]
    metric: string
  }
}

defineOptions({
  name: 'CitySegments',
})

const props = withDefaults(defineProps<CyclabilityMapSegmentsProps>(), {
  geojson: () => newFeatureCollection(),
  metric: 'score',
  selected: () => [],
  layerId: 'city-segments',
})

const emit = defineEmits(['ready'])

const { geojson, metric, classBreaks, outline } = toRefs(props)
const { translateIfExists } = useLabelTranslation()

const layers = ref<Layer[]>([])
const metricLabel = computed(() => `${translateIfExists(`cyclability.data.roads.${metric.value}`, metric.value)} :`)

watch([outline, classBreaks], ([outline, classBreaks]) => {
  const newLayersDefinition = []
  const geometriesFilter = ['==', ['typeof', ['get', props.metric]], 'number']

  newLayersDefinition.push({
    id: `${props.layerId}-border`,
    beforeId: props.layerId,
    type: 'line',
    paint: {
      'line-color': 'black',
      'line-opacity': 0.66,
      'line-width': 16,
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
      'line-miter-limit': 2,
    },
    filter: geometriesFilter,
  } as Layer)

  if (outline) {
    newLayersDefinition[0].paint = {
      'line-color': getExpressionColorFromBreaks(
        outline.classBreaks,
        outline.metric,
        'case',
      ),
      'line-opacity': 1,
      'line-width': 18,
    }
  }

  if (classBreaks) {
    const colorBreaks = getExpressionColorFromBreaks(classBreaks, metric.value, 'case')

    newLayersDefinition.push({
      id: props.layerId,
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          PALETTE_COLORS[0],
          colorBreaks,
        ],
        'line-width': 12,
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-miter-limit': 2,
      },
      filter: geometriesFilter,
    })
  }

  layers.value = newLayersDefinition
}, { immediate: true })

onBeforeUnmount(() => {
  layers.value = []
})
</script>

<template>
  <MapLibrePopup
    :offset="[0, -5]"
    :layer-id="layerId"
  >
    <template #element="{ feature }">
      <div class="flex w-28 flex-col whitespace-normal text-xs">
        <p v-if="getRoadMetrics.cycleRap().includes(metric as RoadMetric)">
          <span class="text-nowrap">{{ metricLabel }}</span><br>
          <span class="font-bold">{{ labels?.[feature?.properties?.[metric] - 1] }}</span>
        </p>
        <p v-else-if="metric === 'pothole'">
          <span class="text-nowrap">{{ metricLabel }}</span><br>
          <span class="font-bold">{{ labels?.[feature?.properties?.[metric]] }}</span>
        </p>
        <p v-else>
          <span class="text-nowrap">{{ metricLabel }}</span><br>
          <span class="font-bold">{{ feature?.properties?.[metric] }}</span><br>
          <span
            v-if="outline"
            class="text-xs text-gray-500"
          >
            <span class="text-nowrap">{{ translateIfExists(`cyclability.data.roads.${outline.metric}`, outline.metric) }} :</span><br>
            <span class="font-bold">{{ outline.labels?.[feature?.properties?.[outline.metric] - 1] }}</span>
          </span>
        </p>
      </div>
    </template>
  </MapLibrePopup>

  <MapLibreSourceGeojson
    :id="layerId"
    :layer-props="layers"
    :data="geojson"
    :geojson-options="{
      promoteId: 'name',
    }"
    :selected-features="selected || []"
    @ready="() => emit('ready')"
  />
</template>
