<script setup lang="ts">
defineOptions({
  name: 'CityCyclabilitySharedBikesPerfMetric',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())
const { writeCache } = useWriteCacheForMetric('cityCyclabilitySharedBikesPerf')
const { dataInRange, dateRange } = useBarcelonaData()

function updateCachedData() {
  if (!city.value) {
    return
  }

  writeCache({
    city: city.value?.name,
    dateRange: dateRange.value,
  }, {
    series: [
      {
        name: 'station',
        label: 'Station-based bikes',
        color: '#bbc9f5',
        data: dataInRange.value?.map(item => [formatDate(item.date), item.freeFloatingTrips]) || [],
        __typename: 'TimelineSerie',
      },
      {
        name: 'free-floating',
        label: 'Free-floating bikes',
        color: '#5578e6',
        data: dataInRange.value?.map(item => [formatDate(item.date), item.stationBasedTrips]) || [],
        __typename: 'TimelineSerie',
      },
    ],
  })
}

watch([dataInRange], () => {
  updateCachedData()
}, { immediate: true })
</script>

<template>
  <MetricCard
    kpi="cityCyclabilitySharedBikesPerf"
    :used-filters="['dateRange']"
    :variables="{
      dateRange,
    }"
  >
    <template #title>
      {{ t('cyclability.shared_bike_perf.title') }}
    </template>

    <template #default="{ data }">
      <BarMetric
        :data="data"
        :options="{
          color: d => PALETTE_COLORS_WEEK[d.name],
        }"
      />
    </template>
  </MetricCard>
</template>
