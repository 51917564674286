export async function fetchGzip(url: string): Promise<string> {
  if (!url.endsWith('.gz')) {
    url += '.gz'
  }

  const response = await fetch(url)

  if (!response.ok) {
    throw new Error('Network request failed')
  }

  const body = response.body

  if (!body) {
    throw new Error('Response body is null')
  }

  const gzip = new DecompressionStream('gzip')
  const reader = body.pipeThrough(gzip).getReader()
  let str = ''

  while (true) {
    const { done, value } = await reader.read()
    if (done) {
      break
    }
    str += new TextDecoder().decode(value)
  }

  return str
}

export async function fetchGzipJson(url: string): Promise<object> {
  const response = await fetchGzip(url)
  return JSON.parse(response.toString())
}
